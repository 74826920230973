import { SaltedgeCountry } from '@/types/saltedge'
import DenmarkFlagUrl from '@components/Generic/CountrySelect/customFlags/denmark.svg'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mapCountries = (documentTypes: any) =>
  documentTypes.map((country) => {
    if (country.countryCode === 'DK' || country.countryCode === 'DNK') {
      return {
        value: country.countryCode,
        label: `${country.countryName} | ${country.countryNameEN}`,
        icon: DenmarkFlagUrl,
        ...country,
      }
    }

    return {
      value: country.countryCode,
      label: `${country.countryName} | ${country.countryNameEN}`,
      icon: country.countryCode,
      ...country,
    }
  })

export const mapCountriesArray = (countryList: string[]): SaltedgeCountry[] =>
  countryList
    ? Object.keys(countryList).map((countryCode) => {
        const country = countryList[countryCode]

        if (countryCode === 'DK' || countryCode === 'DNK') {
          return {
            value: countryCode,
            label: `${country.countryName} | ${country.countryNameEN}`,
            icon: DenmarkFlagUrl,
            countryCode: countryCode,
            ...country,
          }
        }

        return {
          value: countryCode,
          label: `${country.countryName} | ${country.countryNameEN}`,
          icon: countryCode,
          countryCode: countryCode,
          ...country,
        }
      })
    : []

export const mapCountriesFromSaltedgeArray = (
  countryList: { name: string; code: string }[]
) =>
  countryList
    ? countryList.map((country) => {
        if (country.code === 'DK' || country.code === 'DNK') {
          return {
            value: country.code,
            label: country.name,
            icon: DenmarkFlagUrl,
            countryCode: country.code,
            ...country,
          }
        }

        return {
          value: country.code,
          label: country.name,
          icon: country.code,
          countryCode: country.code,
          ...country,
        }
      })
    : []
