import { observer } from 'mobx-react'
import { useContext } from 'react'
import { StoreContext } from '../../App'
import { Header, LoadingSpinner } from '@/components/Generic'
import React from 'react'
import { CenteredContent } from '@/styles/layout.styles'

export const Redirect = observer(() => {
  const store = useContext(StoreContext)
  const { theme } = store.InterfaceState
  const { saltedgePage: trans } = store.TranslationsState.translations

  return (
    <>
      <Header {...theme.header} fontFamily={theme.globals.fontFamilyHeadline}>
        {trans.redirecting}
      </Header>
      <>
        <LoadingSpinner
          width="68px"
          padding="100px 0"
          {...theme.loadingSpinner}
        />

        <CenteredContent margin="20px 0 0 0">{`${trans.pleaseWait}`}</CenteredContent>
      </>
    </>
  )
})
