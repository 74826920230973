import { Header, LoadingSpinner, TextInput } from '../../Generic'
import { observer } from 'mobx-react'
import React, { useContext, useEffect, useState } from 'react'
import { StoreContext } from '../../App'
import {
  BackButton,
  BankBadge,
  BankBadgeIcon,
  BankBadgeList,
  BankBadgeListEmpty,
  BankBadgeName,
  BanksListLabel,
  CountryWrap,
} from '../../../styles/generic.styles'
import { CenteredContent } from '../../../styles/layout.styles'
import { SaltedgeCountry, SaltedgeProvider } from '@/types/saltedge'
import { onKeyDownHandler } from '@/methods/keyboardAccessibility'

interface SelectBankProps {
  providers: SaltedgeProvider[]
  country: SaltedgeCountry
  initSaltedge: (provider: string) => void
  prevStep: () => void
}

export const SelectBank = observer(
  ({ providers, country, initSaltedge, prevStep }: SelectBankProps) => {
    const store = useContext(StoreContext)
    const { theme } = store.InterfaceState
    const { saltedgePage: trans } = store.TranslationsState.translations

    const [search, setSearch] = useState('')
    const [filteredProviders, setFilteredProviders] = useState([])

    useEffect(() => {
      setFilteredProviders(
        search
          ? providers.filter((provider) =>
              provider.name.toLowerCase().includes(search.toLowerCase())
            )
          : providers
      )
    }, [search, providers])

    const goBackHandler = () => {
      setSearch('')
      prevStep()
    }

    return (
      <>
        <BackButton onClick={goBackHandler}>
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M19.4595 21.4595C19.2057 21.7134 18.7941 21.7134 18.5403 21.4595L13.5403 16.4595C13.2864 16.2057 13.2864 15.7941 13.5403 15.5403L18.5403 10.5403C18.7941 10.2864 19.2057 10.2864 19.4595 10.5403C19.7134 10.7941 19.7134 11.2057 19.4595 11.4595L14.9191 15.9999L19.4595 20.5403C19.7134 20.7941 19.7134 21.2057 19.4595 21.4595Z"
              fill="black"
            />
          </svg>
          {trans.back}
        </BackButton>
        <Header {...theme.header} fontFamily={theme.globals.fontFamilyHeadline}>
          {trans.chooseBankHeader}
        </Header>
        <CountryWrap>
          {!providers.length ? (
            <>
              <LoadingSpinner
                width="68px"
                padding="100px 0"
                {...theme.loadingSpinner}
              />
              <CenteredContent margin="20px 0 0 0">{`${trans.banksListLoading} ${country.name}`}</CenteredContent>
            </>
          ) : (
            <>
              <TextInput
                type="text"
                value={search}
                onChange={(value) => {
                  setSearch(value)
                }}
                placeholder={trans.bankSearchPlaceholder}
                {...theme.textInput}
                id="bank-search-input"
              />

              <BanksListLabel data-testid="chooseDocument">
                {`${trans.banksIn} ${country.name}`}
              </BanksListLabel>
              {filteredProviders.length ? (
                <BankBadgeList>
                  {filteredProviders.map((provider) => (
                    <BankBadge
                      key={provider.code}
                      onClick={() => initSaltedge(provider.code)}
                      tabIndex={0}
                      role="button"
                      onKeyDown={(event: KeyboardEvent) =>
                        onKeyDownHandler(event, () =>
                          initSaltedge(provider.code)
                        )
                      }
                    >
                      <BankBadgeIcon>
                        <img src={provider.logoUrl} />
                      </BankBadgeIcon>
                      <BankBadgeName>{provider.name}</BankBadgeName>
                    </BankBadge>
                  ))}
                </BankBadgeList>
              ) : (
                <BankBadgeListEmpty>
                  <div>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M9.99967 3.04183C6.15669 3.04183 3.04134 6.15718 3.04134 10.0002C3.04134 13.8431 6.15669 16.9585 9.99967 16.9585C13.8427 16.9585 16.958 13.8431 16.958 10.0002C16.958 6.15718 13.8427 3.04183 9.99967 3.04183ZM1.95801 10.0002C1.95801 5.55887 5.55838 1.9585 9.99967 1.9585C14.441 1.9585 18.0413 5.55887 18.0413 10.0002C18.0413 14.4415 14.441 18.0418 9.99967 18.0418C5.55838 18.0418 1.95801 14.4415 1.95801 10.0002Z"
                        fill="currentColor"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M9.99967 5.29183C10.2988 5.29183 10.5413 5.53434 10.5413 5.8335V10.8335C10.5413 11.1326 10.2988 11.3752 9.99967 11.3752C9.70052 11.3752 9.45801 11.1327 9.45801 10.8335V5.8335C9.45801 5.53434 9.70052 5.29183 9.99967 5.29183Z"
                        fill="currentColor"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M10.6247 13.5418C10.6247 13.887 10.3449 14.1668 9.99967 14.1668C9.6545 14.1668 9.37467 13.887 9.37467 13.5418C9.37467 13.1967 9.6545 12.9168 9.99967 12.9168C10.3449 12.9168 10.6247 13.1967 10.6247 13.5418Z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                  <div>{trans.bankSearchError}</div>
                </BankBadgeListEmpty>
              )}
            </>
          )}
        </CountryWrap>
      </>
    )
  }
)
