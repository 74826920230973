import { Button, CountrySelect, Header, Icon } from '../../Generic'
import { observer } from 'mobx-react'
import React, { useContext } from 'react'
import { StoreContext } from '../../App'
import {
  ButtonImgWrap,
  ButtonText,
  CountryWrap,
} from '../../../styles/generic.styles'
import {
  ContentContainerBottomFixed,
  ContentContainerBottomLeft,
} from '../../../styles/layout.styles'
import { SaltedgeCountry } from '@/types/saltedge'

interface SelectCountryProps {
  apiCountries: SaltedgeCountry[]
  country: SaltedgeCountry
  handleCountry: (value: string) => void
  handleProceed: () => void
  mobileVersion: boolean
}

export const SelectCountry = observer(
  ({
    apiCountries,
    country,
    handleCountry,
    handleProceed,
    mobileVersion,
  }: SelectCountryProps) => {
    const store = useContext(StoreContext)
    const { theme } = store.InterfaceState
    const { saltedgePage: trans } = store.TranslationsState.translations

    return (
      <>
        <Header {...theme.header} fontFamily={theme.globals.fontFamilyHeadline}>
          {trans.chooseCountryHeader}
        </Header>
        <CountryWrap>
          {mobileVersion ? (
            <>
              <CountrySelect
                externalList={apiCountries}
                value={country}
                onChange={(value) => handleCountry(value)}
                autoFocus={true}
                defaultMenuIsOpen={false}
                placeholder={trans.countrySelectPlaceholder}
                {...theme.selectInput}
                showCheckmark={theme.wcag}
              />
            </>
          ) : (
            <>
              <CountrySelect
                externalList={apiCountries}
                value={country}
                onChange={(value) => handleCountry(value)}
                autoFocus={false}
                defaultMenuIsOpen={false}
                placeholder={trans.countrySelectPlaceholder}
                {...theme.selectInput}
                showCheckmark={theme.wcag}
              />
            </>
          )}
        </CountryWrap>

        <ContentContainerBottomFixed>
          {!mobileVersion ? <ContentContainerBottomLeft /> : <></>}
          <Button
            onClick={() => handleProceed()}
            disabled={!country}
            {...theme.button}
            width="164px"
            paddingMobile="14px 40px"
            id="page-submit"
          >
            <ButtonImgWrap $hide={theme.wcag}>
              <Icon size="18px" type="checkmark" />
            </ButtonImgWrap>
            <ButtonText>{trans.continue}</ButtonText>
          </Button>
        </ContentContainerBottomFixed>
      </>
    )
  }
)
