import { ContentContainer, LoadingSpinner } from '../components/Generic'
import { observer } from 'mobx-react'
import React, { useContext, useEffect, useState } from 'react'
import { StoreContext } from '../components/App'
import { axiosInstance } from '../methods/axiosConfig'
import { mapCountriesFromSaltedgeArray } from '@/methods/mapCountries'
import { forceRedirect } from '@/methods/forceRedirect'
import { devLog } from '@/methods/devLog'
import { SelectCountry } from '@/components/Saltedge/steps/SelectCountry'
import { SelectBank } from '@/components/Saltedge/steps/SelectBank'
import { SaltedgeCountry, SaltedgeProvider } from '@/types/saltedge'
import { Redirect } from '@/components/Saltedge/steps/Redirect'

export const SaltedgePage = observer(() => {
  const store = useContext(StoreContext)
  const { theme } = store.InterfaceState
  const { pageWidth } = store.AppState
  const { currentScenarioId } = store.ScenarioState

  const API_URL = process.env.WEB_API_URL

  const [step, setStep] = useState<number>(0)
  const [country, setCountry] = useState<SaltedgeCountry | null>(null)
  const [apiCountries, setApiCountries] = useState<SaltedgeCountry[]>([])
  const [providers, setProviders] = useState<SaltedgeProvider[]>([])

  const [mobileVersion, setMobileVersion] = useState(false)

  const nextStep = () => {
    setStep(step + 1)
  }

  const prevStep = () => {
    setProviders([])
    setStep(step - 1)
  }

  const getCountries = async () => {
    try {
      const res = await axiosInstance.get(`${API_URL}/salt-edge/countries`, {
        withCredentials: true,
      })

      const countriesArray = mapCountriesFromSaltedgeArray(res.data.countries)

      res.data?.countries && setApiCountries(countriesArray)
      nextStep()
    } catch (e) {
      throw e
    }
  }

  const handleProceed = async () => {
    if (currentScenarioId !== 'localTests') {
      nextStep()

      const res = await axiosInstance.get(
        `${API_URL}/salt-edge/providers?country=${country.value}`,
        {
          withCredentials: true,
        }
      )
      res.data?.providers && setProviders(res.data?.providers)
    }
  }

  const handleCountry = (country) => {
    if (country) {
      const countryApiObj = apiCountries.find(
        (apiObj) => apiObj.countryCode === country.value
      )

      setCountry(countryApiObj)
    } else {
      setCountry(null)
    }
  }

  useEffect(() => {
    if (apiCountries.length === 1) {
      handleCountry(apiCountries[0])
    }

    if (currentScenarioId === 'localTests') {
      const countryApiObj = apiCountries.find(
        (apiObj) => apiObj.countryCode === 'PL'
      )

      setCountry(countryApiObj)
    }
  }, [apiCountries])

  useEffect(() => {
    if (pageWidth && pageWidth <= 980) {
      setMobileVersion(true)
    }
  }, [pageWidth])

  useEffect(() => {
    getCountries()
  }, [])

  const initSaltedge = async (provider: string) => {
    nextStep()

    try {
      const { data } = await axiosInstance.post<{ redirectUrl: string }>(
        `${API_URL}/salt-edge/start-session`,
        { provider: provider },
        {
          withCredentials: true,
        }
      )

      forceRedirect(data.redirectUrl)
    } catch (error) {
      devLog('error: ', error)
    }
  }

  const renderCurrentStep = () => {
    switch (step) {
      case 0:
        return (
          <LoadingSpinner
            width="68px"
            padding="100px 0"
            {...theme.loadingSpinner}
          />
        )
      case 1:
        return (
          <SelectCountry
            apiCountries={apiCountries}
            country={country}
            mobileVersion={mobileVersion}
            handleCountry={handleCountry}
            handleProceed={handleProceed}
          />
        )
      case 2:
        return (
          <SelectBank
            providers={providers}
            country={country}
            initSaltedge={initSaltedge}
            prevStep={prevStep}
          />
        )
      case 3:
        return <Redirect />
      default:
        return <></>
    }
  }

  return (
    <ContentContainer
      {...theme.container}
      width="560px"
      paddingMobile="20px"
      marginMobile="10px"
    >
      {renderCurrentStep()}
    </ContentContainer>
  )
})
